import https from "./https"
import store from "@/store"

const pushMessage = {
  getPushMessages(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/push-messages`, { params })
  },

  createPushMessage(params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/push-messages`, params)
  },

  getPushMessage(pushMessage) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/push-messages/${pushMessage}`)
  },

  updatePushMessage(pushMessage, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/push-messages/${pushMessage}`, params)
  },
  removePushMessage(pushMessage, params) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/push-messages/${pushMessage}`, {params})
  },
  uploadImage(file) {
    return https.post(`general/images/upload`, file)
  }



}

export default pushMessage
