<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'PushMessage',
              }"
              >LINE 主控台</b-breadcrumb-item
            >
            <b-breadcrumb-item active>群發訊息</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-12 col-xl-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">群發訊息</h4>
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            justify-content-start justify-content-xl-end
          "
        >
          <b-button
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            :to="{
              name: 'PushMessageDetail',
              params: {
                messageID: 'create',
              },
            }"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增訊息</b-button
          >
        </div>
      </div>

      <div
        class="
          d-flex
          flex-wrap
          mb-3
          justify-content-start justify-content-lg-end
        "
      >
        <div class="col-md-2 mb-2 mb-md-0">
          <datepicker
            class="mr-2"
            v-model="query.start_at"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            clear-button
          ></datepicker>
        </div>
        <div style="line-height: 45px">～</div>
        <div class="col-md-2 mb-2 mb-md-0">
          <datepicker
            v-model="query.end_at"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            clear-button
          ></datepicker>
        </div>
        <b-button @click="fetchMessages"><i class="fa fa-search"></i></b-button>
      </div>

      <b-tabs v-model="currentTabIndex" @input="handleChangeTab">
        <b-tab v-for="tab in tabs" :title="tab.title" :key="tab.id">
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="messages"
                  :fields="fields"
                  :busy="isFetch"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(title)="data">
                    <router-link
                      :to="{
                        name: 'PushMessageDetail',
                        params: {
                          messageID: data.item.id,
                        },
                      }"
                    >
                      <!-- <b-img
                        fluid
                        rounded="0"
                        src="https://picsum.photos/250/250/?image=54"
                        alt="Image 1"
                        class="mr-1"
                      ></b-img> -->
                      {{ data.item.title }}
                    </router-link>
                  </template>

                  <template #cell(target)="data">
                    <div>
                      {{ data.item.group_id }}
                    </div>
                  </template>
                  <template #cell(advanced)="data">
                    <div>
                      {{ data.item.advanced }}
                    </div>
                  </template>

                  <template #cell(publish_at)="data">
                    <div class="mb-1">
                      {{
                        format(
                          new Date(data.item.publish_at),
                          "yyyy-MM-dd HH:mm"
                        )
                      }}
                    </div>
                  </template>

                  <template #cell(updated_at)="data">
                    <div class="mb-1">
                      {{
                        format(
                          new Date(data.item.updated_at),
                          "yyyy-MM-dd HH:mm"
                        )
                      }}
                    </div>
                  </template>

                  <template #cell(actions)="data">
                    <b-dropdown variant="secondary">
                      <template slot="button-content">
                        <i class="mdi mdi-menu"></i>
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'PushMessageDetail',
                          params: {
                            messageID: data.item.id,
                          },
                        }"
                      >
                        查看
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteMessage(data.item)">
                        刪除
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>

            <div
              class="d-flex justify-content-center align-items-center"
              style="margin-top: 80px"
            >
              <b-pagination
                class="separated mb-0"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="groupbuyList"
                @change="handleChangePage"
              ></b-pagination>
              <div class="ml-2">
                每頁顯示數量<b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  style="width: 70px"
                  class="ml-2"
                  @change="handleChangePage"
                ></b-form-select>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import pushMessageApi from "../../../apis/push-message";
import { mapState } from "vuex";
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";

import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";

import "bootstrap-vue/dist/bootstrap-vue.css";

import checkPermission from "@/mixins/checkPermission";

export default {
  mixins: [checkPermission],
  components: {
    BTable,
    Datepicker,
  },
  data() {
    return {
      zh,
      isFetch: false,
      tabs: [
        {
          id: "draft",
          title: "草稿",
        },
        {
          id: "scheduled",
          title: "已預約傳送",
        },
        {
          id: "success",
          title: "已傳送",
        },
        {
          id: "fail",
          title: "無法傳送",
        },
      ],

      messages: [],
      sortBy: "send_at",
      sortDesc: false,
      fields: [
        {
          key: "title",
          label: "標題",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "target",
          label: "對象",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "audience_count",
          label: "受眾數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "delivered_count",
          label: "送達數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "unique_clicks",
          label: "不重複點擊數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "unique_impression",
          label: "不重複觀看數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "advanced",
          label: "進階設定",
          thClass: "text-center",
          sortable: true,
        },
        {
          key: "publish_at",
          label: "傳送時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "編輯時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "操作",
          thClass: "text-left",
        },
      ],
      perPageOptions: [10, 50, 100],
      currentTabIndex: 0,
      perPage: 10,
      currentPage: 1,
      total: 0,
      query: {
        start_at: "",
        end_at: "",
      },
    };
  },
  watch: {
    currentTabIndex () {
      this.currentPage = 1;
      console.log('ccc', this.currentPage)
    },
  },
  computed: {
    currentTabStatus() {
      return this.tabs[this.currentTabIndex].status;
    },
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  mounted() {
    this.fetchMessages();
  },

  methods: {
    format,
    async fetchMessages() {
      this.isFetch = true;

      try {
        const params = {
          page: this.currentPage,
          status: this.currentTabIndex,
        };

        const { data } = await pushMessageApi.getPushMessages(params);

        this.total = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.perPage = data.meta.per_page;
        this.messages = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },

    handleChangeTab(tab) {
      this.fetchMessages();
      this.currentPage = 1;
      const status = this.tabs.find((t) => t.status === tab)?.id;
      this.$router.push({ query: { tab: status } });
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.fetchMessages();
      });
    },

    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.currentPage = 1;
      this.fetchMessages();
    },

    async deleteMessage(message) {
      this.isFetch = true;

      try {
        const newDate = new Date(message.publish_at);


        const params = {
          title: message.title,
          publish_at: `${format(newDate, "yyyy-MM-dd")}T${format(
            newDate,
            "HH:mm:ss"
          )}`,
          status: message.status,
        };

        console.log(params);

         await pushMessageApi.removePushMessage(
          message.id,
          params
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除 ",
        });
      } catch (error) {        
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: error,
        });
      }
      this.isFetch = false;
    },
  },
};
</script>

<style lang="scss">
</style>
